import React from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

const MasterAccountList: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>アカウント名</TableCell>
              <TableCell>メールアドレス</TableCell>
              <TableCell>状態</TableCell>
              <TableCell>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* サンプルデータ */}
            <TableRow>
              <TableCell>マスターアカウント１</TableCell>
              <TableCell>master1@example.com</TableCell>
              <TableCell>有効</TableCell>
              <TableCell>
                <Button variant="contained" color="primary" sx={{ mr: 1 }}>
                  編集
                </Button>
                <Button variant="contained" color="secondary">
                  削除
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>マスターアカウント２</TableCell>
              <TableCell>master2@example.com</TableCell>
              <TableCell>無効</TableCell>
              <TableCell>
                <Button variant="contained" color="primary" sx={{ mr: 1 }}>
                  編集
                </Button>
                <Button variant="contained" color="secondary">
                  削除
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default MasterAccountList;
