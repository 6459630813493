import React, { useState, useEffect } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

interface Account {
  id: number;
  name: string;
  email: string;
  status: string;
}

const TempAccountList: React.FC = () => {
  const [accounts, setAccounts] = useState<Account[]>([]);

  // データを取得する関数を用意
  useEffect(() => {
    // 実際にはAPIを使ってデータを取得することができます
    const fetchData = async () => {
      const sampleData: Account[] = [
        { id: 1, name: 'Dr.testMan1', status: 'ABCクリニック', email: 'test1@example.com'  },
        { id: 2, name: 'Dr.testMan2', status: 'ABCクリニック', email: 'test2@example.com' },
        { id: 3, name: 'Dr.testMan3', status: 'ABCクリニック', email: 'test3@example.com' },
        { id: 4, name: 'Dr.testMan4', status: 'ABCクリニック', email: 'test4@example.com' },
        // 必要に応じてサンプルデータを追加
      ];
      setAccounts(sampleData);
    };

    fetchData();
  }, []);

  return (
    <Container maxWidth="lg"> {/* コンテナの横幅を'lg'に設定して、テーブルを横幅いっぱいに */}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>医師名</TableCell>
              <TableCell>所属クリニック</TableCell>
              <TableCell>連絡先メールアドレス</TableCell>
              <TableCell>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts.map((account) => (
              <TableRow key={account.id}>
                <TableCell>{account.name}</TableCell>
                <TableCell>{account.status}</TableCell>
                <TableCell>{account.email}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" sx={{ mr: 1 }}>
                    編集
                  </Button>
                  <Button variant="contained" color="secondary">
                    削除
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default TempAccountList;
