import React, { useState } from 'react';
import { Box, Typography, Grid, Paper, List, ListItem, ListItemText, Divider, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// 棒グラフのデータとオプション
const inventoryData = {
  labels: ['7/4', '7/5', '7/6', '7/7', '7/8', '7/9', '7/10'],
  datasets: [
    {
      label: '在庫数',
      data: [50, 45, 55, 60, 65, 70, 75],
      backgroundColor: 'rgba(75, 192, 192, 0.5)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: '日数による在庫推移',
    },
  },
};

// ポイント増減履歴のデータ（サンプルデータ）
const pointHistory = [
  { date: '2024-07-01', description: '＊＊＊クリニック使用/エクソソーム１', change: -100 },
  { date: '2024-07-02', description: '入金（ポイント追加）', change: +1000 },
  { date: '2024-07-03', description: '＊＊＊クリニック使用/エクソソーム１', change: -200 },
  { date: '2024-07-04', description: '入金（ポイント追加）', change: +500 },
  { date: '2024-07-05', description: '＊＊＊クリニック使用/エクソソーム１', change: -300 },
  { date: '2024-07-06', description: '入金（ポイント追加）', change: +1200 },
  { date: '2024-07-07', description: '＊＊＊クリニック使用/エクソソーム１', change: -400 },
];

const notifications = [
  "・ポイント残高減少のお知らせ",
  "・次回メンテナンスのお知らせ: 2024-08-15",
  "・システムアップデートのお知らせ: 2024-09-01",
  // 追加のお知らせ項目
];

const Home: React.FC = () => {
  const [selectedMonth, setSelectedMonth] = useState<string>('2024-07');

  const handleMonthChange = (event: SelectChangeEvent<string>) => {
    setSelectedMonth(event.target.value);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: '550px', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ flexGrow: 1, overflow: 'auto', border: '1px solid #ccc', borderRadius: '4px', mb: 0 }}>
              <Typography variant="h6" gutterBottom sx={{ position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1, p: 1 }}>
                お知らせ
              </Typography>
              <List sx={{ p: 0, m: 0 }}>
                {notifications.map((notification, index) => (
                  <ListItem key={index} sx={{ p: 0 }}>
                    <ListItemText primary={notification} primaryTypographyProps={{ variant: 'body2', sx: { lineHeight: '1.5' } }} />
                  </ListItem>
                ))}
              </List>
            </Box>
            <Typography variant="h6" sx={{ mt: 3 }} gutterBottom>
              ポイント推移
            </Typography>
            <Bar data={inventoryData} options={options} />
            <Typography variant="h6" sx={{ mt: 3 }}>
              ポイント残高: 100000
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: '550px', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" gutterBottom>
                ポイント増減詳細履歴
              </Typography>
              <Select
                value={selectedMonth}
                onChange={handleMonthChange}
                displayEmpty
                inputProps={{ 'aria-label': '月別選択' }}
              >
                <MenuItem value="2024-07">2024年7月</MenuItem>
                <MenuItem value="2024-06">2024年6月</MenuItem>
                <MenuItem value="2024-05">2024年5月</MenuItem>
                {/* 他の月も追加可能 */}
              </Select>
            </Box>
            <Box sx={{ flexGrow: 1, overflow: 'auto', border: '1px solid #ccc', borderRadius: '4px', p: 1 }}>
              <List>
                {pointHistory.map((entry, index) => (
                  <React.Fragment key={index}>
                    <ListItem>
                      <ListItemText
                        primary={`${entry.description}`}
                        secondary={`日付: ${entry.date} - ポイント変動: ${entry.change > 0 ? '+' : ''}${entry.change}`}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
